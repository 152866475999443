import backgroundImage from './assets/banner-image.jpeg'
import umamiFullHorizontal from './assets/umami-full-horizontal.png'
import kanji from './assets/kanji-vertical.png'
import Page from './shared/Page'
import styled from 'styled-components'
import { HTMLAttributes } from 'react'
import { MdAccessTimeFilled, MdLocalPhone, MdLocationPin } from 'react-icons/md'
import cssUtils from './styles/cssUtils'
import { greySet } from './styles/colorSets'

export default function Home() {
  return (
    <Page backgroundImage={backgroundImage} fullScreenImage centered>
      <Container className="mx-2 px-xs-3 pt-xs-3 pb-xs-2 p-lg-4">
        <TopWrapper className="mb-2 align-top">
          <div className="kanji">
            <img src={kanji} alt="kanji" />
          </div>
          <div className="full-logo pl-2">
            <img src={umamiFullHorizontal} alt="main-logo" />
            <Details className="py-4 px-2 text-light" />
          </div>
        </TopWrapper>

        <div className="justify-centered">
          <iframe
            src="https://www.yelp.com/waitlist/umami-japanese-barbeque-vineyard/widget?theme=dark"
            width="250"
            height="326"
            frameBorder="0"
            title="Join our waitlist"
          ></iframe>
        </div>
      </Container>
    </Page>
  )
}

const Container = styled.div`
  background-color: ${cssUtils.opacity(greySet[900], 0.5)};
  border-radius: 12px;
`

const TopWrapper = styled.div`
  .kanji {
    width: 16%;
  }

  .full-logo {
    flex: 1;
  }

  img {
    width: 100%;
  }
`

function Details(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <DetailRow icon={<MdLocationPin size={28} />}>
        <a
          href="https://goo.gl/maps/77Yb5Ti6Fa4yiLEm6"
          target="_blank"
          rel="noreferrer"
        >
          568 N Mill Road Suite 107, Vineyard, Utah 84058
        </a>
      </DetailRow>
      <DetailRow icon={<MdAccessTimeFilled size={22} />}>
        M-Th 11AM-9PM Fri & Sat 11AM-10PM
      </DetailRow>
      <DetailRow icon={<MdLocalPhone size={24} />}>
        <a href="tel:385-329-0488">(385) 329-0488</a>
      </DetailRow>
    </div>
  )
}

interface DetailRowProps {
  icon: JSX.Element
}

function DetailRow({
  icon,
  children,
}: DetailRowProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="text-light align-top mb-3">
      {icon}
      <span className="pl-1">{children}</span>
    </div>
  )
}
