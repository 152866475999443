import React, { HTMLAttributes } from 'react'
import { MenuCategory, MenuItem } from '../data/types'
import PriceRow from '../shared/PriceRow'
import { getCategoriesByParent, menuItemsByCategory } from './util'

interface Props {
  category: string
  categories: MenuCategory[]
  items: MenuItem[]
  level: number
}

export default function MenuSubsection({
  category,
  categories,
  items: unfilteredItems,
  level,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const subcategories = getCategoriesByParent(categories, category)
  const items = menuItemsByCategory(unfilteredItems, category)
  return (
    <div {...props}>
      {level === 1 && <h4 className="pt-2">{category}</h4>}
      {level === 2 && <PriceRow item={category} />}
      {items.map((item) => (
        <PriceRow
          key={item.item}
          item={item.item}
          price={item.price}
          variant={level === 2 ? 'secondary' : 'primary'}
        />
      ))}
      {subcategories.map((subcategory, idx) => (
        <MenuSubsection
          className={idx ? 'pt-2' : ''}
          key={subcategory.category}
          category={subcategory.category}
          items={menuItemsByCategory(unfilteredItems, subcategory.category)}
          level={2}
          categories={categories}
        />
      ))}
    </div>
  )
}
