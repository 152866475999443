import { HTMLAttributes } from 'react'

export default function Skeleton({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const classes = ['loading']
  if (className) {
    classes.push(className)
  }
  return <div className={classes.join(' ')} {...props}></div>
}
