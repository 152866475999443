import { HTMLAttributes } from 'react'
import { NavLink, useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { greySet, orangeSet } from '../styles/colorSets'

interface Props {
  orientation?: 'vertical' | 'horizontal'
}

export default function NavLinks({
  orientation = 'vertical',
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  return (
    <Container isVertical={orientation === 'vertical'} {...props}>
      <NavItem to="/about" className="px-2 pt-1">
        About Us
      </NavItem>
      <NavItem to="/menu" className="px-2 pt-1">
        Menu
      </NavItem>
    </Container>
  )
}

interface NavItemProps {
  to: string
}
function NavItem({
  to,
  children,
  className,
}: NavItemProps & HTMLAttributes<HTMLAnchorElement>) {
  const match = useMatch(to)

  const classes = ['text-bold']
  if (match) {
    classes.push('active')
  }

  if (className) {
    classes.push(className)
  }

  return (
    <StyledNavItem to={to} className={classes.join(' ')}>
      {children}
    </StyledNavItem>
  )
}

const StyledNavItem = styled(NavLink)`
  color: ${greySet[100]};
  text-decoration: none;
  font-size: 16px;
  position: relative;

  &:link,
  &:visited {
    color: ${greySet[100]};
  }

  :after {
    content: '';
    background-image: linear-gradient(
      90deg,
      ${orangeSet[900]} 0%,
      ${orangeSet[300]} 100%
    );
    height: 2px;
    position: absolute;
    top: 100%;
    margin-top: 4px;
    transition: 0.3s all ease;
    left: 50%;
    width: 0;
  }

  &:hover,
  &.active {
    :after {
      left: 0;
      width: 100%;
    }
  }
`

const Container = styled.div<{ isVertical: boolean }>`
  ${StyledNavItem} {
    ${({ isVertical }) =>
      isVertical
        ? `
      display: block;
      margin-bottom: var(--gutter-3);
    `
        : `
      margin-left: var(--gutter-3);
    `}
  }
`
