import styled, { css } from 'styled-components'
import { HTMLAttributes, useState } from 'react'
import Navbar, { NAV_BAR_HEIGHT } from '../nav/Navbar'
import Drawer from '../Drawer'
import { greySet } from '../styles/colorSets'

interface Props {
  backgroundImage: string
  fullScreenImage?: boolean
  headerText?: string
  centered?: boolean
}

export default function Page({
  backgroundImage,
  fullScreenImage = false,
  headerText,
  centered = false,
  children,
}: Props & HTMLAttributes<HTMLDivElement>) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return fullScreenImage ? (
    <>
      <FullImageBackground backgroundImage={backgroundImage} />
      <Navbar onToggleDrawer={handleDrawerToggle} isDrawerOpen={isDrawerOpen} />
      <FullImageContentWrapper
        className={centered ? 'align-center' : ''}
        onClick={() => setIsDrawerOpen(false)}
      >
        <StyledMain className="mb-3">{children}</StyledMain>
        <Drawer isOpen={isDrawerOpen} />
      </FullImageContentWrapper>
    </>
  ) : (
    <>
      <Navbar onToggleDrawer={handleDrawerToggle} isDrawerOpen={isDrawerOpen} />
      <Drawer isOpen={isDrawerOpen} />
      <BannerImageContentWrapper
        onClick={() => setIsDrawerOpen(false)}
        className="flex-column"
      >
        <BannerImageContainer backgroundImage={backgroundImage}>
          <div className="banner-inner px-xs-4">
            <h1 className="light">{headerText}</h1>
          </div>
        </BannerImageContainer>
        <BannerImageMain className="pb-6">{children}</BannerImageMain>
      </BannerImageContentWrapper>
    </>
  )
}

const FullImageBackground = styled.div<{ backgroundImage: string }>`
  position: fixed;
  height: 100%;
  width: 100%;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  overflow-x: hidden;
`

const FullImageContentWrapper = styled.div`
  position: relative;
  padding-top: ${NAV_BAR_HEIGHT};
  flex: 1;
`

const centeredContent = css`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`

const BannerImageContentWrapper = styled.div`
  background-color: ${greySet[50]};
  flex: 1;
`

const BannerImageContainer = styled.div<{ backgroundImage: string }>`
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  padding-top: ${NAV_BAR_HEIGHT};
  .banner-inner {
    ${centeredContent}
  }
`

const StyledMain = styled.main`
  ${centeredContent}
`

const BannerImageMain = styled(StyledMain)`
  flex: 1;
  width: 100%;
`
