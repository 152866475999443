import styled from 'styled-components'

export default styled.button`
  background-color: transparent;
  border: none;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter-1);
  svg {
    color: #fff;
  }
`
