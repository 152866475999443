import styled from 'styled-components'
import { greySet } from '../styles/colorSets'
import cssUtils from '../styles/cssUtils'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import logo from '../assets/umami-full-horizontal-small.png'
import { Cell, Grid } from './Grid'

const year = new Date().getFullYear()

export default function Footer() {
  return (
    <StyledFooter className="justify-centered">
      <InnerWrapper className="text-light-secondary serif px-1 py-2 mx-lg-auto mx-xs-2">
        <Cell sm={12} lg={12} className="space-between align-bottom pb-1">
          <div>
            <img src={logo} alt="logo" />
          </div>
          <div className="pb-1">
            <a
              href="https://www.facebook.com/Umamijapanesebarbecue/"
              className="mr-1"
              title="Facebook"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://www.instagram.com/umamijapanesebarbeque/"
              title="Instagram"
            >
              <FaInstagram size={24} />
            </a>
          </div>
        </Cell>
        <Cell sm={12} lg={12} className="pb-2" style={{ color: greySet[700] }}>
          Umami Japanese BBQ © {year}
        </Cell>
      </InnerWrapper>
    </StyledFooter>
  )
}

const StyledFooter = styled(Grid)`
  background-color: ${greySet[900]};
  box-shadow: ${cssUtils.boxShadow()};
  z-index: 1;
`

const InnerWrapper = styled.div`
  max-width: 1200px;
`
