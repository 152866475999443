import { useLayoutEffect, useState } from 'react'
import { MdClose, MdMenu } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../assets/umami-nav-horizontal.png'
import IconButton from '../shared/IconButton'
import { greySet } from '../styles/colorSets'
import cssUtils from '../styles/cssUtils'
import NavLinks from './NavLinks'

export const NAV_BAR_HEIGHT = '82px'

interface Props {
  onToggleDrawer: () => void
  isDrawerOpen: boolean
}

export default function Navbar({ onToggleDrawer, isDrawerOpen }: Props) {
  const [isSticky, setIsSticky] = useState(false)

  useLayoutEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <StyledNavBar className="px-4 py-2" isSticky={isSticky || isDrawerOpen}>
      <nav className="align-center space-between">
        <NavLink to="/">
          <img src={logo} alt="Umami logo" />
        </NavLink>
        <IconButton
          style={{ marginRight: '-4px' }}
          className="menu-button mobile"
          onClick={onToggleDrawer}
        >
          {isDrawerOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
        </IconButton>
        <NavLinks className="desktop" orientation="horizontal" />
      </nav>
    </StyledNavBar>
  )
}

const StyledNavBar = styled.header<{ isSticky: boolean }>`
  top: 0;
  background-color: transparent;
  box-shadow: none;
  transition: background-color 400ms ease, box-shadow 400ms ease;
  z-index: 1;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  height: ${NAV_BAR_HEIGHT};

  ${({ isSticky }) =>
    isSticky
      ? `

    box-shadow: ${cssUtils.boxShadow()};
    background-color: ${greySet[900]};
  `
      : ``}
`
