import getPresentationData from '../get-presentation-data'
import { BuffetItem, Menu, MenuCategory, MenuItem, Sheet } from '../types'

class MenuCache {
  private menu: Menu | undefined

  getMenu(): Promise<Menu> {
    if (this.menu) {
      return new Promise((resolve) => {
        resolve(this.menu as Menu)
      })
    }

    return getPresentationData([
      Sheet.menu_category,
      Sheet.menu_item,
      Sheet.menu_lunch_buffet,
      Sheet.menu_dinner_buffet,
    ]).then((results) => {
      if (results.length !== 4) {
        throw new Error(
          'Failed to retrieve all necessary components for the menu.'
        )
      }
      const menu: Menu = {
        categories: results[0] as unknown as MenuCategory[],
        items: results[1] as unknown as MenuItem[],
        lunchBuffetItems: results[2] as unknown as BuffetItem[],
        dinnerBuffetItems: results[3] as unknown as BuffetItem[],
      }

      this.menu = menu

      return menu
    })
  }
}

export default new MenuCache()
