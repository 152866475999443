import { useEffect, useState } from 'react'
import menuCache from './cache/menuCache'
import { Menu } from './types'

export default function useMenu() {
  const [data, setData] = useState<Menu>()
  const [error, setError] = useState<Error | undefined>()

  useEffect(() => {
    setError(undefined)
    menuCache
      .getMenu()
      .then(setData)
      .catch((error: Error) => setError(error))
  }, [])

  return { data, loading: !data, error }
}
