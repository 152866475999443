import useMenu from '../data/useMenu'
import Page from '../shared/Page'
import banner from '../assets/menu-banner.jpeg'
import MenuSections from './MenuSections'
import { ColumnLayout } from '../shared/ColumnLayout'
import Skeleton from '../shared/Skeleton'
import styled from 'styled-components'
import { breakpoints } from '../styles/layout'

export default function Menu() {
  const { data, loading } = useMenu()

  return (
    <Page backgroundImage={banner} headerText="Menu">
      {loading && <MenuSkeleton />}
      {!loading && data && (
        <ColumnLayout className="pb-4" lg={2}>
          <MenuSections menu={data} />
        </ColumnLayout>
      )}
    </Page>
  )
}

function MenuSkeleton() {
  return (
    <StyledMenuSkeleton className="pb-4 pt-3" lg={2}>
      <Skeleton className="no-break-inside menu-skeleton mb-3" />
      <Skeleton className="no-break-inside menu-skeleton mb-3" />
      <Skeleton className="no-break-inside menu-skeleton mb-3" />
      <Skeleton className="no-break-inside menu-skeleton mb-3" />
    </StyledMenuSkeleton>
  )
}

const StyledMenuSkeleton = styled(ColumnLayout)`
  width: 100%;

  .menu-skeleton {
    height: 800px;
  }

  @media (max-width: ${breakpoints.lg}) {
  }
`
