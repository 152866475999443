import styled from 'styled-components'
import { NAV_BAR_HEIGHT } from './nav/Navbar'
import NavLinks from './nav/NavLinks'
import { greySet } from './styles/colorSets'
import cssUtils from './styles/cssUtils'

interface Props {
  isOpen: boolean
}

const DRAWER_WIDTH = '280px'

export default function Drawer({ isOpen }: Props) {
  return (
    <StyledDrawer isOpen={isOpen}>
      <NavLinks />
    </StyledDrawer>
  )
}

const StyledDrawer = styled.aside<{ isOpen: boolean }>`
  position: fixed;
  top: ${NAV_BAR_HEIGHT};
  width: ${DRAWER_WIDTH};
  height: 100%;
  background-color: ${greySet[800]};
  border-radius: 0 6px 6px 0;
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-105%')});
  transition: transform 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: ${({ isOpen }) => (isOpen ? cssUtils.boxShadow() : 'none')};
  padding: var(--gutter-1);
  box-sizing: border-box;
  z-index: 1;
`
