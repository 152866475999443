import axios from 'axios'
import qs from 'qs'
import { Sheet } from './types'

const client = axios.create({
  baseURL:
    'https://content-sheets.googleapis.com/v4/spreadsheets/1DjEhUnrOMu5iO5ccM3IDXsSM7Y5JD-sTzMCwHb3EgdA/values:batchGet',
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

export default client

export function buildParams(ranges: Sheet[]) {
  return {
    key: 'AIzaSyAY5Z61p142H4qXENdOE770-BVdhuXE598',
    ranges,
  }
}
