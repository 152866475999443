import React from 'react'
import styled from 'styled-components'
import { Menu } from '../data/types'
import { NoBreakCell } from '../shared/ColumnLayout'
import PriceRow from '../shared/PriceRow'
import useIsMobile from '../shared/useIsMobile'
import MenuSubsection from './MenuSubsection'
import Sodas from './Sodas'
import {
  getBuffetItems,
  getCategoriesByParent,
  menuItemsByCategory,
  rankSort,
  mobileRankSort,
} from './util'

interface Props {
  menu: Menu
}

export default function MenuSections({ menu }: Props) {
  const isMobile = useIsMobile()
  const { categories, items } = menu
  const topCategories = categories
    .filter((category) => !category.parentCategory)
    .sort(isMobile ? mobileRankSort : rankSort)

  return (
    <>
      {topCategories.map((category, idx) => (
        <NoBreakCell key={category.category} className="p-3">
          <h2 key={category.category}>{category.category}</h2>
          {menuItemsByCategory(items, category.category).map((item) => (
            <React.Fragment key={item.item}>
              <PriceRow item={item.item} price={item.price} />
              {item.additionalInfo && <p>{item.additionalInfo}</p>}
              {item.info && <Description>{item.info}</Description>}
              {item.showDrinksGraphic && <Sodas />}
              {getBuffetItems(menu, item.buffetItems)
                .sort(rankSort)
                .map((buffetItem) => (
                  <div key={buffetItem.item} className="pl-4">
                    {buffetItem.item}
                  </div>
                ))}
            </React.Fragment>
          ))}
          {getCategoriesByParent(categories, category.category).map(
            (subcategory) => (
              <MenuSubsection
                key={subcategory.category}
                level={1}
                category={subcategory.category}
                categories={categories}
                items={items}
              />
            )
          )}
        </NoBreakCell>
      ))}
    </>
  )
}

const Description = styled.p.attrs({ className: 'caption' })`
  padding: var(--gutter-1) 0 var(--gutter-2) 0;
  margin: 0;
`
