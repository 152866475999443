import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { fonts, fontSizes, textColors } from '../styles/typography'

type Variant = 'primary' | 'secondary'

interface Props {
  item: string
  price?: string
  variant?: Variant
}

export default function PriceRow({
  item,
  price,
  variant = 'primary',
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledPriceRow variant={variant} {...props}>
      <span className="item">{item}</span>
      {price && (
        <>
          <Dots />
          <span className="ml-auto price">{price}</span>
        </>
      )}
    </StyledPriceRow>
  )
}

const Dots = styled.div`
  border-bottom: 2px dotted ${textColors.body};
  flex-grow: 1;
  position: relative;
  top: -8px;
  margin: 0 2px;
`

const StyledPriceRow = styled.div<{ variant: Variant }>`
  align-items: flex-end;
  width: 100%;
  display: flex;
  font-family: ${fonts.serif};

  .price {
    font-size: ${fontSizes.h4};
  }

  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return `
            font-size: ${fontSizes.h4};
        `
      case 'secondary':
        return `
            font-size: ${fontSizes.h5};
            padding-left: var(--gutter-2);
            .item {
                padding-bottom: 2px;
            }
        `
    }

    return ''
  }}
`
