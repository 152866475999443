import styled from 'styled-components'
import Calpis from '../assets/sodas/Calpis.jpeg'
import CherryCoke from '../assets/sodas/CherryCoke.jpeg'
import Coke from '../assets/sodas/Coke.jpeg'
import DietCoke from '../assets/sodas/DietCoke.jpeg'
import DrPepper from '../assets/sodas/DrPepper.jpeg'
import Fresca from '../assets/sodas/Fresca.jpeg'
import GingerAle from '../assets/sodas/GingerAle.jpeg'
import MelloYello from '../assets/sodas/MelloYello.jpeg'
import RootBeer from '../assets/sodas/RootBeer.jpeg'
import Sprite from '../assets/sodas/Sprite.jpeg'
import SweetTea from '../assets/sodas/SweetTea.jpeg'

interface Drink {
  img: string
  description: string
}

const drinks: Drink[] = [
  { description: 'Calpis', img: Calpis },
  { description: 'Cherry Coke', img: CherryCoke },
  { description: 'Coke', img: Coke },
  { description: 'Diet Coke', img: DietCoke },
  { description: 'Dr Pepper', img: DrPepper },
  { description: 'Fresca', img: Fresca },
  { description: 'Ginger Ale', img: GingerAle },
  { description: 'Mello Yello', img: MelloYello },
  { description: 'Root Beer', img: RootBeer },
  { description: 'Sprite', img: Sprite },
  { description: 'Sweet Tea', img: SweetTea },
]

export default function Sodas() {
  return (
    <StyledSodas className="pb-2 pt-1">
      {drinks.map((drink) => (
        <img src={drink.img} alt={drink.description} key={drink.description} />
      ))}
    </StyledSodas>
  )
}

const StyledSodas = styled.div`
  img {
    width: 15%;
  }
`
