import { camelCase } from 'lodash'
import client, { buildParams } from './client'
import { RawSheetData, Sheet } from './types'

export default function getPresentationData(sheets: Sheet[]) {
  return client
    .get('', { params: buildParams(sheets) })
    .then((results) => serializer(results.data.valueRanges as RawSheetData))
}

function serializer(sheets: RawSheetData) {
  return sheets.map((sheet) => {
    const headers = sheet.values[0] ?? []
    const rows = sheet.values.slice(1)

    return rows.map((values) => {
      const record: Record<string, string | number | boolean> = {}
      values.forEach((value, idx) => {
        if (value) {
          if (!headers[idx]) {
            throw new Error('Unable to find data header')
          }

          record[camelCase(String(headers[idx]))] = formatValue(value)
        }
      })
      return record
    })
  })
}

function formatValue(value: string) {
  if (value === 'TRUE') {
    return true
  }

  if (value === 'FALSE') {
    return false
  }

  return isNumber(value) ? parseFloat(value) : value
}

function isNumber(value: string | number | null | undefined) {
  if (typeof value === 'string' && value.length === 0) {
    return false
  }

  if (value === null) {
    return false
  }

  return !isNaN(value as number)
}
