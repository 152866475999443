import styled from 'styled-components'
import { breakpoints } from '../styles/layout'

export const ColumnLayout = styled.div<{ sm?: number; lg?: number }>`
  // lg
  @media (min-width: ${breakpoints.lg}px) {
    column-count: ${(props) => props.lg ?? 1};
  }

  // sm
  @media (max-width: ${breakpoints.lg}px) {
    column-count: ${(props) => props.sm ?? 1};
  }
`

export const NoBreakCell = styled.div`
  page-break-inside: avoid;
`
