import { css, keyframes } from 'styled-components'
import { greySet } from './colorSets'

const loadingAnimation = keyframes`
  0% {
    background-color: ${greySet[100]}
  }
  50% {
    background-color: ${greySet[200]}
  }
  100% {
    background-color: ${greySet[100]}
  }
`

export default css`
  --box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.15);

  .loading {
    animation: ${loadingAnimation} 1.5s ease-in-out 0.5s infinite;
    border-radius: 12px;
    color: transparent !important;
  }
`
