import { useEffect, useState } from 'react'
import { breakpoints } from '../styles/layout'

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoints.lg)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoints.lg)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })

  return isMobile
}
