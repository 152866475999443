import { isNil } from 'lodash'
import {
  BuffetType,
  Menu,
  MenuCategory,
  MenuItem,
  RankedItem,
} from '../data/types'

export function menuItemsByCategory(menuItems: MenuItem[], category: string) {
  return menuItems.filter((item) => item.category === category)
}

export function getCategoriesByParent(
  categories: MenuCategory[],
  parent: string
) {
  return categories.filter((category) => category.parentCategory === parent)
}

export function getBuffetItems(menu: Menu, type: BuffetType | undefined) {
  if (type === 'lunch') {
    return menu.lunchBuffetItems
  }

  if (type === 'dinner') {
    return menu.dinnerBuffetItems
  }

  return []
}

export function rankSort(a: RankedItem, b: RankedItem, isMobile?: boolean) {
  if (isNil(a.rank) || isNil(b.rank)) {
    return -1
  }
  if (a.rank > b.rank) {
    return 1
  }

  if (a.rank < b.rank) {
    return -1
  }

  return 0
}

export function mobileRankSort(a: RankedItem, b: RankedItem) {
  if (isNil(a.mobileRank) || isNil(b.mobileRank)) {
    return -1
  }
  if (a.mobileRank > b.mobileRank) {
    return 1
  }

  if (a.mobileRank < b.mobileRank) {
    return -1
  }

  return 0
}
