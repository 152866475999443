import { css } from 'styled-components'
import { greySet, orangeSet } from './colorSets'

export const fonts = {
  sanSerif: '"Kodchasan", sans-serif;',
  serif: '"Nanum Gothic", serif',
}

export const fontSizes = {
  caption: '13px',
  body: '16px',
  large: '24px',
  h5: '16px',
  h4: '24px',
  h3: '32px',
  h2: '38px',
  h1: '44px',
}

export const textColors = {
  body: greySet[600],
  caption: greySet[500],
}

export default css`
  body {
    font-family: ${fonts.sanSerif};
    font-size: ${fontSizes.body};
    color: ${textColors.body};
    line-height: 1.5;
  }

  .text-light {
    color: ${orangeSet[100]};
  }

  .text-light-secondary {
    color: ${greySet[100]};
  }

  .text-black {
    color: ${greySet[900]};
  }

  .text-bold {
    font-weight: 700;
  }

  .serif {
    font-family: ${fonts.serif};
  }

  .caption {
    color: ${textColors.caption};
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    font-family: ${fonts.serif};
    color: ${orangeSet.primary};
    margin-top: 0;
    margin-bottom: var(--gutter-2);

    &.light {
      color: ${orangeSet[100]};
    }

    &.secondary {
      color: ${greySet[700]};
    }
  }

  h1,
  .h1 {
    font-size: ${fontSizes.h1};
  }

  h2,
  .h2 {
    font-size: ${fontSizes.h2};
  }

  h3,
  .h3 {
    font-size: ${fontSizes.h3};
  }

  h4,
  .h4 {
    font-size: ${fontSizes.h4};
  }

  h5,
  .h5 {
    font-size: ${fontSizes.h5};
  }

  a,
  a:link,
  a:visited {
    color: ${orangeSet[600]};
    text-decoration: none;
    cursor: pointer;
  }
`
