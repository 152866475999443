import bannerImg from './assets/about-us-banner.jpg'
import { Cell, Grid } from './shared/Grid'
import Page from './shared/Page'
import img1 from './assets/about-us-image-01.jpeg'
import img2 from './assets/about-us-image-02.jpeg'
import img3 from './assets/about-us-image-03.jpeg'
import styled from 'styled-components'
import cssUtils from './styles/cssUtils'

export default function AboutUs() {
  return (
    <Page backgroundImage={bannerImg} headerText="About Us">
      <Grid direction="row-reverse" className="pt-3 pb-4 px-xs-3">
        <Cell sm={12} lg={6} className="pl-lg-3">
          <StyledImg src={img1} alt="buffet and tables" />
        </Cell>
        <Cell sm={12} lg={6} className="pr-lg-3 pt-xs-2">
          <h1 className="text-black">ようこそ</h1>
          <h2>WHAT IS JAPANESE BARBECUE?</h2>
          <hr />
          <p>
            Yakiniku 焼肉 (Japanese Barbecue) refers to thinly sliced, bite
            sized pieces of meat that are grilled by diners at each table.
            Yakiniku is steeped in rich history and cultural background. Korean
            migrant laborers are known for introducing barbeque to the Japanese
            during the Korean war. Due to its incredible popularity, there are
            well over 20,000 yakiniku restaurants in Japan today.
          </p>
        </Cell>
      </Grid>
      <Grid className="pt-3 pb-4 px-xs-3">
        <Cell sm={12} lg={6} className="pr-lg-3 pt-xs-2">
          <StyledImg src={img2} alt="buffet meats" />
        </Cell>
        <Cell sm={12} lg={6} className="pl-lg-3">
          <h1 className="text-black">ようこそ</h1>
          <h2>EXPERIENCE WHY YAKINIKU IS SO BIG IN JAPAN</h2>
          <hr />
          <p>
            Umami's upscale buffet features 20-30 epicurean selections of meat
            and vegetables including: New York strip steak, harami skirt steak,
            Kurobuta (Japanese Black Pig), wagyu beef, Kobe style beef,
            Australian lamb as well as many other unique cuts and marinades.
            Umami's Side Dish Buffet includes premium rice, sushi, shrimp, gyoza
            (potstickers), ahi poke, salads and much more.
          </p>
          <p>
            Traditional to Japanese barbecue, there are sauces at every table to
            dip your meat and veggies in as they come off the grill. Each table
            at Umami is arrayed with three sauces including garlic shoyu,
            hokkaido miso apple, and roasted sesame.
          </p>
        </Cell>
      </Grid>
      <Grid direction="row-reverse" className="py-3 px-xs-3">
        <Cell sm={12} lg={6} className="pl-lg-3">
          <StyledImg
            src={img3}
            alt="buffet and tables"
            style={{ width: '100%' }}
          />
        </Cell>
        <Cell sm={12} lg={6} className="pr-lg-3 pt-xs-2">
          <h1 className="text-black">メイヤード反応</h1>
          <h2>SMOKELESS GRILLS</h2>
          <hr />
          <p>
            In the center of every table is a top-of-the line gas Shinpo grill
            imported from Japan. Holes around the top ring of the grill
            ventilate any smoke and grease down through the ground. This means
            that you will not leave Umami smelling like smoke on a business
            meeting or date. The smokeless technology allows you to set the
            grill at a well-suited heat to avoid long cooking times and more
            importantly get the ever so coveted maillard reaction that creates
            browning and caramelization. Your meat will envelop in a smoky
            grilled flavor, but you will not. Don't worry, as the rings around
            the grill never get hot, so you don't have to worry about your kids
            or yourself getting burned.
          </p>
        </Cell>
      </Grid>
    </Page>
  )
}

const StyledImg = styled.img`
  width: 100%;
  border-radius: 6px;
  box-shadow: ${cssUtils.boxShadow()};
`
