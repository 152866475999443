import { css } from 'styled-components'
import { greySet, orangeSet } from './colorSets'

export const breakpoints = {
  xs: 0,
  lg: 1200,
}

export default css`
  html,
  body,
  #root {
    width: 100%;
    height: 100vh;
  }

  body {
    overflow-x: hidden;
  }

  html {
    background-color: ${greySet[900]};
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .align-top {
    display: flex;
    align-items: flex-start;
  }

  .align-bottom {
    display: flex;
    align-items: flex-end;
  }

  .justify-centered {
    display: flex;
    justify-content: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .full-height {
    height: 100%;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .mobile {
    @media (min-width: ${breakpoints.lg}px) {
      display: none !important;
    }
  }

  .desktop {
    @media (max-width: ${breakpoints.lg}px) {
      display: none !important;
    }
  }

  hr {
    height: 2px;
    margin-top: var(--gutter-2);
    width: 40px;
    background: linear-gradient(
      90deg,
      ${orangeSet[300]} 0%,
      ${orangeSet[900]} 100%
    );
    margin-left: 0;
    border: none;
  }

  .no-break-inside {
    page-break-inside: avoid;
  }
`
