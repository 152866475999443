import { createGlobalStyle } from 'styled-components'
import layout from './layout'
import misc from './misc'
import spacing from './spacing'
import typography from './typography'

const GlobalStyles = createGlobalStyle`
    ${layout}
    ${spacing}
    ${typography}
    ${misc}
`

export default GlobalStyles
