import { breakpoints } from './layout'

type Breakpoint = keyof typeof breakpoints

type MediaMap = {
  [key in Breakpoint]: { lt?: Breakpoint; gte?: Breakpoint }
}

const mediaMap: MediaMap = {
  xs: {
    lt: 'lg',
  },
  lg: {
    gte: 'lg',
  },
}

function buildMediaQuery(breakpoint: Breakpoint) {
  const parts: string[] = []
  const config = mediaMap[breakpoint]
  if (config.gte) {
    parts.push(`(min-width:${breakpoints[config.gte]}px)`)
  }
  if (config.lt) {
    parts.push(`(max-width:${breakpoints[config.lt]}px)`)
  }
  return parts.join(' and ')
}

function media(breakpoint: keyof typeof breakpoints, cssString: string) {
  const css = `
      @media ${buildMediaQuery(breakpoint)} {
        ${cssString}
      }
    `

  return css
}

const hexRegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

function hexToRgb(hex: string) {
  const matchArr = hex
    .replace(hexRegExp, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)

  if (matchArr) {
    return matchArr.map((x) => parseInt(x, 16))
  }

  return [0, 0, 0]
}

function opacity(hex: string, opacity: number) {
  const [r, g, b] = hexToRgb(hex)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const cssUtils = {
  boxShadow: () => `0px 0px 24px 6px rgba(0, 0, 0, 0.6);`,
  media,
  hexToRgb,
  opacity,
}

export default cssUtils
