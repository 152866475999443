export const greySet = {
  50: '#fcf5f2',
  100: '#f1eae7',
  200: '#e3ddda',
  300: '#d0c9c6',
  400: '#aaa4a1',
  500: '#888380',
  600: '#615c59',
  700: '#4e4947',
  800: '#312c2a',
  900: '#110a06',
  primary: '#110a06',
}

export const orangeSet = {
  50: '#f8f9e3',
  100: '#efeebb',
  200: '#e4e38f',
  300: '#dad863',
  400: '#d4d141',
  500: '#cdca1a',
  600: '#cdb911',
  700: '#cca300',
  800: '#ca8c00',
  900: '#c56600',
  primary: '#cca300',
}
