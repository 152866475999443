export enum Sheet {
  menu_item = 'menu_item',
  menu_category = 'menu_category',
  menu_lunch_buffet = 'menu_lunch_buffet',
  menu_dinner_buffet = 'menu_dinner_buffet',
}

export type SheetData = string[][]

interface ValueRange {
  range: string
  values: SheetData
}

export type RawSheetData = ValueRange[]

export interface SheetSerializer<T> {
  sheet: Sheet
  serializer: (d: SheetData) => T[]
}

export interface RankedItem {
  rank?: number | null
  mobileRank?: number | null
}

export interface MenuCategory extends RankedItem {
  category: string
  parentCategory: string
}

export type BuffetType = 'lunch' | 'dinner'

export interface MenuItem {
  item: string
  category: string
  price: string
  info?: string
  additionalInfo?: string
  buffetItems?: BuffetType
  showDrinksGraphic?: boolean
}

export interface BuffetItem extends RankedItem {
  item: string
}

export interface Menu {
  categories: MenuCategory[]
  items: MenuItem[]
  lunchBuffetItems: BuffetItem[]
  dinnerBuffetItems: BuffetItem[]
}
