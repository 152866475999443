import GlobalStyles from './styles/GlobalStyles'
import Home from './Home'
import { Route, Routes } from 'react-router-dom'
import AboutUs from './AboutUs'
import Footer from './shared/Footer'
import Menu from './Menu/Menu'
import ScrollToTop from './shared/ScrollToTop'

export default function App() {
  return (
    <>
      <ScrollToTop />
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/menu" element={<Menu />} />
      </Routes>
      <Footer />
    </>
  )
}
