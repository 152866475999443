import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../styles/layout'

type ColumnWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type Direction = 'row' | 'row-reverse' | 'column' | 'column-reverse'

interface GridProps {
  direction?: Direction
  gap?: number
}

export function Grid({
  direction = 'row',
  children,
  gap = 0,
  ...props
}: GridProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledGrid direction={direction} gap={gap} {...props}>
      {children}
    </StyledGrid>
  )
}

interface CellProps {
  sm?: ColumnWidth
  lg?: ColumnWidth
}

export function Cell({
  sm = 12,
  lg = 12,
  children,
  ...props
}: CellProps & HTMLAttributes<HTMLDivElement>) {
  const smWidth = calcPercent(sm)
  const lgWidth = calcPercent(lg)
  return (
    <StyledCell smWidth={smWidth} lgWidth={lgWidth} {...props}>
      {children}
    </StyledCell>
  )
}

const StyledGrid = styled.div<{
  direction: Direction
  gap: number | undefined
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: wrap;
`

const StyledCell = styled.div<{ smWidth: string; lgWidth: string }>`
  // lg
  @media (min-width: ${breakpoints.lg}px) {
    width: ${(props) => props.lgWidth};
  }

  // sm
  @media (max-width: ${breakpoints.lg}px) {
    width: ${(props) => props.smWidth};
  }
`

function calcPercent(n: number) {
  const percent = `${(n / 12) * 100}%`
  return percent
}
